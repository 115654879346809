import { inject, Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { AvatarApi } from '@ministrary/shared/apis/avatar.api';
import { BaseApi } from '@ministrary/shared/apis/base.api';
import { ChurchService } from '@ministrary/shared/services/church/church.service';

import { iChild } from '../interfaces/child.interface';

@Injectable({
  providedIn: 'root'
})
export class ChildApi extends BaseApi<iChild> {
  private avatarApi = inject(AvatarApi);
  private churchService = inject(ChurchService);

  constructor() {
    super('child', environment.apiUrl);
  }

  async getAll() {
    const church_id = this.churchService.churchId;

    const { data } = await this.supabase
      .from(this.tableName)
      .select('*, family!inner(users!family_owner_fkey!inner(users_church!users_church_user_id_fkey!inner(church_id)))')
      .eq('family.users.users_church.church_id', church_id)
      .eq('active', true)
      .order('name')
      .returns<iChild[]>();
    return data || [];
  }

  async getByFamilyId(familyId: string) {
    const { data } = await this.select().eq('family_id', familyId).returns<iChild[]>();
    return data;
  }

  async delete(child: iChild) {
    if (child.avatar) await this.avatarApi.delete(child.avatar);
    return super.deleteById(child.id);
  }
}
